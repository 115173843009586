#landing-hero {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: calc(100vh - 56px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#landing-hero-background {
    background-image: url(../../media/wikipedia-running-man.jpeg);
    opacity: 0.2;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#landing-hero-text {
    background-color: rgba(0,0,0,0.2);
    margin: 24px;
    padding: 24px;
    border-radius: 12px;
    position: absolute;
    color: white;
    font-weight: 700;
    text-align: center;
    text-shadow: 1px 1px 1px black;
    position: absolute;
}

#landing-hero-title {
    font-size: 3em;
    color: rgb(206, 255, 212);
}

#landing-hero-subtitle {
    font-size: 1.5em;
}