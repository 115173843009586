#profile-picture {
    width: 100px ;
    height: 100px ;
    padding-left: 0;
    padding-right: 0;
    margin: 16px;
    border-radius: 50%;
    border: 1px solid gray;
    /* display: inline-block; */
}

#profile-name {
    /* display: inline-block; */
    /* width: auto; */
}

/* #champion-first {
    display: inline-block;
    width: auto;
    max-width: auto;
    padding-right: 0;
    font-weight: normal;
    font-size: 24px;
}

#charity-row {
    position: relative;
} */

#charity-image {
    width: 100px;
    padding: 10px;
    border: 1px solid black;
    margin-bottom: 12px;
    margin-left: 12px;
}

/* #charity-recipient {
    display: inline-block;
    width: auto;
    max-width: auto;
    font-size: 24px;
    font-weight: bold;
    position: absolute;
    bottom: 4px;
    left: 120px;
} */

.custom-table-container {
    max-height: 200px;
    overflow-y: scroll;
    border: 1px solid var(--bs-border-color-translucent);
}

.athleteActivityDonationsTable {
    border: 1px solid rgb(188, 0, 188);
    border-radius: 8px;
    overflow: hidden;
}

.athleteActivityDonationsTable thead {
    background-color: rgba(188, 0, 188, 0.05);
}

.athleteActivityDonationsTable table {
    margin-bottom: 0;
    
}

.athleteActivityDonationsTable thead tr {
    border: 0px solid #0000;
    overflow-x: scroll;
}

.athleteActivityDonationsTable thead tr:first-of-type {
    border-bottom: 1px solid rgb(188, 0, 188);
}

.athleteActivityDonationsTable tbody tr {
    border: 0px solid #0000;
    overflow-x: scroll;
}

.athleteActivityDonationsTable tbody tr td {
    border: 0px solid #0000;
}

.athleteActivityDonationsTable thead tr th {
    border: 0px solid #0000;
}


